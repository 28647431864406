@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montagu+Slab:opsz,wght@16..144,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'Sansation';
  src: url('./fonts/Sansation_Bold.ttf') format('truetype');
  font-weight: bold; /* Assuming 'Bold' indicates a bold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Sansation';
  src: url('./fonts/Sansation_Regular.ttf') format('truetype');
  font-weight: 400; /* Assuming 'Bold' indicates a bold weight */
  font-style: normal;
}

Chrome,
Safari,
and Opera ::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
  background-color: #3b2770;
}

::-webkit-scrollbar-thumb {
  background-color: #3b2770;
}

/* Firefox */
/* For Firefox 64+ */
* {
  scrollbar-color: #3b2770 rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
}

/* For Firefox 63 and below */
* {
  scrollbar-color: #3b2770 rgba(0, 0, 0, 0.05);
}

/* Microsoft Edge and IE */
* {
  -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
}

*::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
  background-color: #3b2770;
}

*::-webkit-scrollbar-thumb {
  background-color: #3b2770;
}
body {
  font-family: Montserrat;
}
.font-heading {
  font-size: clamp(1.4rem, 3.2vw + 1.1rem, 3.2rem);
  font-family: Sansation;
  font-weight: 700;
  max-width: 700px;
  color: #191919;
  line-height: 1.2;
}
.wrapper {
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.rs-picker-menu {
  z-index: 20000 !important;
}
.rs-picker-default .rs-picker-toggle {
  border: 1px solid rgba(15, 23, 43, 0.3) !important;
  border-radius: 10px;
  height: 56px;
  width: 100%;
}

.react-calendar {
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.5em;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__prev-button {
  font-weight: bold;
}
.react-calendar__navigation__next-button {
  font-weight: bold;
}
.react-calendar__month-view {
  padding-bottom: 20px;
}
.react-calendar__month-view__weekdays {
  padding: 20px;
  font-size: 12px;
  text-transform: uppercase;
  color: #828282;
}
abbr[title] {
  text-decoration: unset !important;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label {
  font-weight: bold;
}
.react-calendar__navigation button {
  color: #333333;
  min-width: 44px;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__tile {
  padding: 5px;
  margin-bottom: 5px;
  color: #000;
}

.react-calendar__tile--now {
  background: #f5f5f5;
  border-radius: 6px;
  font-weight: bold;
  color: gray;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
  color: #2463eb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #2463eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2463eb;
  color: rgb(198, 176, 176);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #2463eb;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #2463eb;
  color: white;
}

.react-calendar__tile.disabled-date {
  color: #d3d3d3; /* Gray out the text */
  pointer-events: none; /* Disable clicking */
}